import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The conversational space experienced an amazing level of growth in 2019. We saw more and more companies adopt conversation as a way to connect with their customers, and more customers engage with those conversations. With such an amazing year of advancement, we at Dashbot can’t help but to anticipate what 2020 will bring. For our last meetup of this year, we gathered some industry leaders for a discussion on what we can look forward to.`}</p>
    <p>{`Moderator: Arte Merritt, CEO, `}<a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot `}</a></p>
    <p>{`Dave Grossman, CCO, `}<a parentName="p" {...{
        "href": "https://www.earplay.com/"
      }}>{`Earplay`}</a></p>
    <p>{`Kasey Byrne, VP of Marketing, `}<a parentName="p" {...{
        "href": "https://rasa.com/"
      }}>{`RASA`}</a></p>
    <p>{`Kristi Ebong, SVP of Corporate Strategy, `}<a parentName="p" {...{
        "href": "https://orbita.ai/"
      }}>{`Orbita.ai`}</a></p>
    <h3><strong parentName="h3">{`Which industries are suited to this space?`}</strong></h3>
    <p>{`Something all our panelists agreed on is all industries could benefit a conversational interface. According to Kristi Ebong, SVP of Orbita.ai, a healthcare-focused company, the ultimate goal of utilizing a conversational platform is to offload lower level administrative tasks in order to augment face time. For example, at a doctor’s visit, a conversational assistant could be used to take notes so the physician can give the patient their full attention. Ebong also added that people are starting to require that hands-free experience and turning away from anything manual.`}</p>
    <p>{`She also noted that more and more users are making requests that blend their personal and professional lives. Most notably, surgeons are asking to be able to play their own music on the Alexa in the operating room. Surgeons can be operating for up to 20 hours, and having their own music would go a long way. There’s a huge opportunity there for devices that are table to code switch between the professional and personal.`}</p>
    <p>{`VP of Marketing at RASA Kasey Byrne also believes that a well built smart assistant capable of maintaining contextual cues is going to be valuable in any number of industries. She believes that we can see even more traction for smart assistants at the intersection of complex flows and topics people don’t mind interacting with a chatbot for. For example, Byrne developed a chatbot for her kids that determined the level of importance of the matter they would text her about. If it was deemed urgent, the bot would instruct the child to give her a phone call, cutting down on the amount of time they would have to wait.`}</p>
    <p>{`Earplay CCO Dave Grossman related the voice space to the web in the Nineties. Soon all companies are going to have a voice app just to be present in the space. If someone asks Alexa about your company, you want to be in control of what it says back. He anticipates that things that would live in audio, like radio and podcasts, are going to be interested in adding a level of interactivity.`}</p>
    <h3><strong parentName="h3">{`What’s the biggest trend this year?`}</strong></h3>
    <p>{`Grossman noticed that more people were spending more time in the search function of Earplay, which points to a high level of interest. His users want to know what else is available to them instead of just staying with what they already know. Good news for those worried about user acquisition and discovery.`}</p>
    <p>{`Ebong saw a similar trend in the healthcare world. More patients are becoming comfortable interfacing with voice apps and chatbots, especially with topics they feel awkward discussing with real people. Byrne echoed that sentiment, saying she was continuing to hear from RASA users that building a contextual assistant that people are happy to use is a great experience. It’s very gratifying to create something that can help customers.`}</p>
    <p>{`The business behavior is also changing. According to Ebong, business leaders were always asking for ROI on projects only a year ago. The conversation has completely shifted recently. Now the question is how to get the resources to build the conversational interface instead of asking what value it could bring to the company. It took 20 years for 25 percent of US consumers to adopt web. Mobile took five years for that to happen. Conversational interfaces, on the other hand, took only four years to reach those numbers. Companies are looking to have content on these platforms well-developed by the time consumers go looking for their voice apps and chatbots.`}</p>
    <h3><strong parentName="h3">{`How do you acquire users?`}</strong></h3>
    <p>{`The user acquisition and discovery question has been one of the most vexing parts of development. According to Grossman, companies, and especially those in the entertainment industry, don’t do a good job at promotion. However, he pointed to NPR’s “Wait Wait Don’t Tell Me” as a shining example. “Wait Wait Don’t Tell Me” is also a podcast, and they make use of the voice skill on every episode and talk about it constantly. They expose the voice skill to their audience with such frequency that they get more users on it.`}</p>
    <p>{`For Ebong, it’s all about reducing friction. By meeting users on whatever channel they’re already on, you’re removing obstacles to engagement. There’s no additional app to download or link to click. Byrne sees a similar pattern in the most successful RASA users. Conversation is used when its the best and most efficient tool to complete the task. She brought up Talkspace, an app that provides therapy. Instead of filling out a form for intake, users talk to a chatbot and don’t have to deal with a person who they feel might judge them. It’s a simple enough process to improve with technology.`}</p>
    <h3><strong parentName="h3">{`What areas of improvement do you want?`}</strong></h3>
    <p>{`Grossman still sees a huge structural issue with discoverability. Voice apps lack a method of linking. Imagine if we couldn’t link to another page on a website. Suddenly, web discoverability is severely impaired. If voice apps weren’t siloed within themselves, he thinks that adoption would shoot way up and more users would find more voice apps they would like to use.`}</p>
    <p>{`Ebong would like to see a way to take advantage of existing data. There’s nothing more frustrating than filling out the same, dry form twice. If there were a way to collect data on what the customer has already done to avoid repeats, the experience would be a lot more user-friendly. She wants to see a better data flow.`}</p>
    <h3><strong parentName="h3">{`What predictions do you have for 2020?`}</strong></h3>
    <p>{`Grossman expressed excitement for the opportunities that are going to pop up in cars. He also said that devices are going to evolve. He pointed to an upcoming Alexa feature that would allow users to access their favorite assistants no matter the device they have, which he thinks will make more space for everything to exist and also create easier access to everything.`}</p>
    <p>{`Open source continues to be very important to Byrne. RASA is an open source company because they believe in supporting the industry and giving people access to information, especially in a fairly new industry like this one where there are still new use cases to be thought up.`}</p>
    <p>{`Ebong is anticipating a maturation of content versus platform. A lot of the simple chatbots aren’t chatbots at all; they’re just content. Click this button or that. Now she’s starting to see companies start to separate out into robust, complex technologies. There’s also a massive market for content that’s already in conversational format. The players that can provide the content in a digestible way are going to win the space.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p">{`Dashbot is an analytics platform for conversational interfaces that enables enterprises to increase satisfaction, engagement, and conversions through actionable insights and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support DialogFlow, Alexa, Google Assistant, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      